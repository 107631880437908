import { ref, watch, computed } from '@vue/composition-api'
import { getAppStore } from '@/store'

export const useResponsiveAppLeftSidebarVisibility = () => {
  const appStore = getAppStore()
  const mqShallShowLeftSidebar = ref(false)

  // Close Active Sidebars and other stuff when going in large device
  const currentBreakPoint = computed(() => appStore.currentBreakPoint)

  watch(currentBreakPoint, (val, oldVal) => {
    // Reset chats & contacts left sidebar
    if (oldVal === 'md' && val === 'lg') {
      mqShallShowLeftSidebar.value = false
    }
  })

  return {
    mqShallShowLeftSidebar,
  }
}

export const _ = null
